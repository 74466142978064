@import 'variables.scss';

// MATERIAL UI
// .MuiTextField-root {
//   margin-bottom: 18px !important;
// }

.MuiInputLabel-formControl {
  font-family: $font-family !important;
  color: $font-primary !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  opacity: 0.7 !important;
  line-height: 1.3 !important;
}

.MuiInputLabel-shrink {
  transform: translate(14px, -11px) scale(0.85) !important;
}

.MuiOutlinedInput-input {
  border-radius: 8px;
  font-family: $font-family !important;
  color: $font-primary !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 0px 14px !important;
  height: 50px !important;
  box-sizing: border-box !important;
  background-color: white !important;
}

.grey-input {
  background-color: #f5f5f5 !important;
}

.MuiTypography-body1 {
  font-family: $font-family !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0.02em !important;
}

.MuiInputBase-inputMultiline {
  padding: 0px !important;
  height: 90px !important;
  overflow: auto !important;
}

.MuiOutlinedInput-notchedOutline {
  box-sizing: border-box !important;
  border-radius: 8px !important;
}

.MuiFormHelperText-root {
  font-family: $font-family !important;
  font-size: 13px !important;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body,
html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

#root {
  height: 100%;
}

body {
  overscroll-behavior: contain;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  padding: 0;
  margin: 0;
  background-color: $white;
  color: $font-primary;
}

button,
h1,
h2,
h3,
input,
option,
p,
select,
span,
strong,
textarea {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #20f;
  text-decoration: none;
  font-weight: 500;
}
a:visited {
  color: #20f;
}
blockquote {
  color: #666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
}
.dropZone:active {
  opacity: 0.5;
}
.dropZone:focus {
  opacity: 0.5;
}

.accessory-tag {
  width: 128px;
  aspect-ratio: 1 / 1;
}

.price-description {
  font-size: 15px;
  font-weight: 400;
}

.accessory-card {
  width: 236px;
  aspect-ratio: 1.37 / 1;
}

.search-icons-input {
  box-shadow: none !important;
  border: none !important;
  height: 44px !important;
  margin-left: 8px;
}

.ks-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(29, 36, 86, 0.15),
    0 2px 6px 0 rgba(29, 36, 86, 0.05);
  box-sizing: border-box;
  border: 3px solid white;
  background-color: white;
  object-fit: cover;
}

.search-icons-header {
  padding: 6px 20px !important;
}

.search-icons-input:focus {
  box-shadow: none !important;
}

@media (max-width: 767px) {
  .accessory-tag {
    width: 90px;
  }

  .accessory-card {
    width: 128px;
  }
}

.preset-border {
  border-radius: 12px;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 48px;
  min-height: 48px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  padding: 0 10px;
  background: #fff
    url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 99% 50%;
  background-size: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
}
option {
  font-size: 15px;
}

.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f5f4ff !important;
  color: #4e32ff;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #20f !important;
}
.Range .DayPicker-Day {
  border-radius: 2px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  text-decoration: none;
}
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  text-decoration: none;
}
input:read-only:focus {
  box-shadow: none;
}
textarea:read-only:focus {
  box-shadow: none;
}

.clickable-element {
  cursor: pointer;
  font-weight: 500 !important;
  color: #20f !important;
  white-space: nowrap;
  -webkit-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
.clickable-element:hover {
  opacity: 0.8;
}
.clickable-element:active {
  opacity: 0.5;
}
.billing-plan-info {
  font-size: 86%;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.no-wrap {
  white-space: nowrap;
}

.letter-spacing-small {
  letter-spacing: 0.25px;
}
.letter-spacing-medium {
  letter-spacing: 0.5px;
}
.letter-spacing-big {
  letter-spacing: 0.5px;
}
.clip-text {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal-out-animation {
  animation: nf-fade-out 200ms ease-out !important;
}

.toast-out-animation {
  animation: nf-fade-out 300ms ease-out !important;
}

@-webkit-keyframes nf-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes nf-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(30px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

// Switch.js
/* The switch - the box around the slider */
.switch-small {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch-small input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-small {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: none;
  outline: none;
}

.slider-small:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider-small {
  background-color: #000;
}

input:checked + .slider-small:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider-small.round {
  border-radius: 34px;
}

.slider-small.round:before {
  border-radius: 50%;
}

// RequiredSwitch.js
.required-switch-small {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 16px;
}

/* Hide default HTML checkbox */
.required-switch-small input {
  opacity: 0;
  width: 0;
  height: 0;
}

.required-slider-small {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: none;
  outline: none;
}

.required-slider-small:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

input:checked + .required-slider-small {
  background-color: #0b9e58;
}

input:checked + .required-slider-small:before {
  transform: translateX(30px);
}

/* Rounded sliders */
.required-slider-small.round {
  border-radius: 34px;
}

.required-slider-small.round:before {
  border-radius: 50%;
}

.ks-modal-wrapper {
  position: absolute !important;
}

.ks-card-container {
  position: static !important;
}

.primary-compact-button {
  background-color: #405de6 !important;
  font-size: 13px !important;
  height: 36px !important;
  box-shadow: none !important;
  color: #ffffff !important;
  border: none !important;
}

.compact-button {
  border: 1px solid #c3cdd3 !important;
  background-color: #ededed !important;
  font-size: 13px !important;
  height: 36px !important;
  box-shadow: none !important;
}

.add-button {
  border: 1px solid #000 !important;
  background-color: transparent !important;
  font-size: 13px !important;
  height: 28px !important;
  box-shadow: none !important;
  padding: 0 12px !important;
  margin-right: 8px !important;
}

.save-button {
  border: none !important;
  background-color: #0b9e58 !important;
  color: #fff !important;
  font-size: 13px !important;
  height: 32px !important;
  box-shadow: none !important;
  padding: 0 18px !important;
  margin: -5px 0 !important;
}

.compact-button-outline {
  border: 1px solid #c3cdd3 !important;
  background-color: #fff !important;
  font-size: 13px !important;
  height: 36px !important;
  box-shadow: none !important;
}

.extra-height {
  height: 36px !important;
}

.export-csv {
  border: 1px solid #38978d !important;
}

.desktop-contact-actions {
  display: flex;
  position: absolute;
  right: 12px;
  top: 7px;
}

#rc-editable-input-26 {
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  border-radius: 2px !important;
}

#rc-editable-input-27 {
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  border-radius: 2px !important;
}
